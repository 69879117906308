<template>
  <div style="padding: 20px">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>
      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">筛选条件</div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent
            >
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label-width="120px" label="服务商名称">
                    <el-input
                      v-model="where.name"
                      placeholder="服务商域名"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :lg="4" :md="12" style="text-align: center">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList"
                      >查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">服务商列表</div>
            </div>
            <div></div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :total="total"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :customsFromWhere="where"
              :loading="loading"
            >
              <template v-slot:is_bind="{ scope }">
                <span v-if="scope.row.is_bind == 0">未绑定</span>
                <span v-else>已绑定</span>
              </template>
              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot="{ scope }">
                <el-link
                  :underline="false"
                  type="info"
                  v-if="scope.row.is_bind == 0"
                  v-auths="[
                    `${$config.uniquePrefix}operate:facilitator:status`,
                  ]"
                  @click="untie('0', scope.row)"
                  >绑定</el-link
                >
                <el-link
                  v-else
                  :underline="false"
                  type="danger"
                  v-auths="[
                    `${$config.uniquePrefix}operate:facilitator:status`,
                  ]"
                  @click="untie('1', scope.row)"
                >
                  解绑
                </el-link>
              </template>
            </my-table>
          </div>
        </div>
      </el-card>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="40%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div class="flex">
        <span class="zuobianjudingshirenwu">{{ row.http }}</span>
        <el-input
          class="w-50"
          v-model="domain"
          width="60"
          placeholder="请输入域名地址"
        ></el-input>
        <span class="youbianjudingshirenwu">{{ row.url }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closet">取 消</el-button>
        <el-button type="primary" @click="unties()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 引入自定义表格组件
import MyTable from "@/components/MyTable";
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";

// 权限
import { ShowTable } from "@/utils";
// 引入的接口
import { getVendordomain, unbinddomain, Bounddomain } from "@/api/operate";

export default {
  name: "index",
  // 支柱道具属性
  props: {},
  // 组件生效
  components: {
    MyTable,
    PackageSortElement,
  },
  // Data数据
  data() {
    return {
      // 表格搜索条件
      //存储域名地址
      domain: "",
      where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      dialogVisible: false,
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading: true,
      //用来控制单条的数据
      row: {
        http: "",
        url: "",
      },
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true,
        },
        {
          label: "服务商名称",
          prop: "company_name",
          isShow: true,
        },
        {
          label: "服务商域名",
          prop: "sld",
          isShow: true,
        },
        {
          label: "状态",
          prop: "is_bind",
          slot: "is_bind",
          isShow: true,
        },
        {
          label: "操作",
          width: "200",
          prop: "operationSlot",
          slot: "operationSlot",
          isShow: true,
        },
      ],

      // 列表设置弹窗
      ListSetupShow: false,
    };
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.getList();
  },

  destroyed() {
    this.getList();
  },

  // 方法集合
  methods: {
    getList() {
      getVendordomain(this.where)
        .then(async (res) => {
          this.List = res.data;
          this.total = res.data.count;
          this.loading = false;
        })
        .catch((res) => {
          this.$Message.error(res.msg);
          this.loading = false;
        });
    },
    untie(value, row) {
      let that = this;
      if (value == "0") {
        this.dialogVisible = true;
        this.row = row;
      } else {
        this.$utils.confirm(
          "确定要解除绑定吗？",
          function () {
            unbinddomain(row.fid).then((res) => {
              if (res.code == 200) {
                that.$message.success(res.msg);
                that.getList();
              } else {
                that.$message.error(res.msg);
              }
            });
          },
          "确定",
          "warning"
        );
      }
    },
    unties() {
      if (this.domain == "") {
        this.$message.error("请输入域名");
        return false;
      }
      let sld = this.domain;
      let data = { sld: sld };
      Bounddomain(this.row.fid, data).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.getList();
          this.domain = "";
          this.dialogVisible = false;
        } else {
          this.$message.error(res.msg);
          this.domain = "";
          this.dialogVisible = false;
        }
      });
    },
    closet() {
      this.dialogVisible = false;
      this.domain = "";
    },
    // 查询条件
    QueryList() {
      this.getList();
    },
    // 清空
    empty() {
      this.where = {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      };
      this.getList();
    },
    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name";
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA";
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name";
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 点击列表设置
    ListSetup() {
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose() {
      this.ListSetupShow = false;
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 24px;
  font-weight: 500;
  color: #707070;
  margin-bottom: 20px;
}

.condition {
  .condition_t {
    display: flex;
    align-items: center;
  }
  .condition_t_icon {
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .condition_t_text {
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }
}
.flex {
  display: flex;
  align-items: center;
}
.w-50 {
  width: 400px;
}
.youbianjudingshirenwu {
  margin-left: 15px;
}
.zuobianjudingshirenwu {
  margin-right: 15px;
}
</style>
